
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ReportWidget extends Vue {
  @Prop() qtype!: string;
  @Prop() private date!: Date;
  @Prop() comment!: string;
  @Prop() private reportKey!: string;

  get formatDate() {
    return new Date(this.date).toLocaleString('us', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  }

  downLoadBtn() {
    this.$store.dispatch('fetchReportUrl', this.reportKey);
  }
}
