var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"level-bar",style:({
    width: `${_vm.checkValue * 100}%`,
    'background-color': _vm.color.fill,
    'border-right': `1px solid ${_vm.stroke}`,
  }),attrs:{"title":_vm.value}},[_c('p',{staticClass:"value-text",style:({
      color: _vm.activeColor(_vm.value),
      left: _vm.textLeft,
    })},[_vm._v(" "+_vm._s(_vm.printValue)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }