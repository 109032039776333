
import { Component, Vue } from 'vue-property-decorator';
import ReportWidget from '@/components/customer/ReportWidget.vue';

@Component({ components: { ReportWidget } })
export default class FullReportPage extends Vue {
  mockReportList = [
    {
      type: 'Q2 Cyber Report',
      date: new Date(),
      text: 'Twist’s rating has improved since becoming an Intangic customer',
    },
    {
      type: 'Q1 Cyber Report',
      date: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      text: 'Rating performance improvements relative to peers seen in cyber (Cy) factors, particularly reconnaissance activity. Financial (Fi) factors stable. Overall good momentum to build on; consolidate gains, address areas for improvement.',
    },
  ];

  get reportList() {
    return this.$store.getters.companyReports.map((el: any) => {
      return {
        text: el.ReportSummary,
        date: new Date(el.ReportDate),
        type: el.ReportTitle,
        reportKey: el.ReportKey,
      };
    });
  }

  /*
    {
        "ReportKey": "advisoryReports/UFC8NYMNFSYIHNSM2I38_lei/2022-04-16/Intangic Case Study - Viasat.pdf",
        "ReportSummary": "This document highlights the early warning signal capabilities of Intangic's CyFi model in the case of the Viasat breach announced in March 2022.",
        "DataType": "CompanyReportPDF#2022-04-16",
        "Grouping": "UFC8NYMNFSYIHNSM2I38_lei",
        "ReportTitle": "Early Warning Use Case",
        "ReportDate": "2022-04-16"
    }
  */
}
