
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LevelBar extends Vue {
  @Prop() private value!: number;
  @Prop() private max!: number;
  @Prop() private color!: {
    stroke: string;
    fill: string;
    strokeFade?: string;
    text: string;
    textFade: string;
  };

  countGraphValue(value: number, max: number): number {
    if (max === 0) return 0;
    return value / max;
  }

  get printValue() {
    if (this.value > 1) return this.value.toFixed(0);
    if (this.value === 0) return this.value.toFixed(0);
    return this.value.toFixed(2);
  }

  get checkValue() {
    if (this.max === 0) return 0;
    return this.value / this.max;
  }

  get stroke(): string {
    if (this.checkValue === 0 && this.color.strokeFade) return this.color.strokeFade;
    return this.color.stroke;
  }

  activeColor(value: number): string {
    if (value === 0) return this.color.textFade;
    return this.color.text;
  }

  get textLeft(): string {
    if (this.checkValue * 100 <= 40) {
      return `calc(100% + 6px)`;
    }
    return `6px`;
  }
}
